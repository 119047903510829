import './home.css';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Button from './components/Button';

const decode = (str) => decodeURIComponent(atob(str));

const nls = {
  contactUS: {
    en: 'Contact us',
    zh: '联系我们',
  },
  lang: {
    en: '中 文',
    zh: 'English',
  },
  email: {
    en: 'E-Mail',
    zh: '需求邮箱',
  },
  phone: {
    en: 'Mobile',
    zh: '联系电话',
  },
  address: {
    en: 'Address',
    zh: '地址',
  },
  location: {
    en: decode('QmxkLiUyMEQlMjBOby4xNTUlMjBYaW5TaGVuZyUyMFJkLiUyMFNoYW5naGFp'),
    zh: decode('JUU0JUI4JThBJUU2JUI1JUI3JUU1JUI4JTgyJUU5JTlEJTkyJUU2JUI1JUE2JUU1JThDJUJBJUU2'
      + 'JTk2JUIwJUU4JTgzJTlDJUU4JUI3JUFGMTU1JUU1JThGJUI3RCVFNSVCOSVBMg=='),
  },
  website: {
    en: 'Website',
    zh: '网址',
  },
  company: {
    en: decode('U2hhbmdoYWklMjBEQyUyMFRlY2guJTIwTFRELg=='),
    zh: decode('JUU0JUI4JThBJUU2JUI1JUI3JUU3JUFDJTgzJUU3JUIyJUI5JUU3JUE3JT'
      + 'kxJUU2JThBJTgwJUU2JTlDJTg5JUU5JTk5JTkwJUU1JTg1JUFDJUU1JThGJUI4'),
  },
};

export default function Home({ lang = 'en' }) {
  const [contact, setContact] = useState(false);
  return (
    <article>
      <section className={`contacts ${contact ? 'contacts-push' : ''}`}>
        <p>
          <span />
          {nls.company[lang]}
        </p>
        <p>
          <span>
            {nls.email[lang]}
            :
          </span>
          <a href={decode('bWFpbHRvJTNBUkZRLnNlcnZpY2UlNDBzaGR1Y3VpLmNvbQ==')}>
            {decode('UkZRLnNlcnZpY2UlNDBzaGR1Y3VpLmNvbQ==')}
          </a>
        </p>
        <p>
          <span>
            {nls.phone[lang]}
            :
          </span>
          {decode('JTJCODYlMjAxNTgtMjE4MC0yNzg4')}
        </p>
        <p>
          <span>
            {nls.address[lang]}
            :
          </span>
          {nls.location[lang]}
        </p>
        <p>
          <span>
            {nls.website[lang]}
            :
          </span>
          www.shducui.com
        </p>
      </section>
      <section className="home-link-buttons">
        <Button onClick={() => setContact(!contact)}>
          {nls.contactUS[lang]}
        </Button>
        <Link to={`/${lang === 'en' ? 'zh' : 'en'}`}>
          <Button>{nls.lang[lang]}</Button>
        </Link>
      </section>
      <section className={`home-bg home-bg-${lang} ${contact ? 'home-bg-push' : ''}`} />
      <section className="beian">
        <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
          {decode('JUU2JUIyJUFBSUNQJUU1JUE0JTg3MjAyMjAxMzY1MyVFNSU4RiVCNy0x')}
        </a>
      </section>
    </article>
  );
}
